.my_video {
  position: absolute;
  top: 4px;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.container_s,
.container_b {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.container_b {
  flex-direction: row;
  justify-content: space-between;
}

.images {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.image_container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.product_image {
  width: 100%;
  height: auto;
  border-radius: 1rem;
}

.details_wrapper {
  margin-top: 2rem;
  z-index: 1;
  width: 100%;

  .cartStyles {
    display: flex;
    justify-content: flex-end;
    top: -50px;
    position: relative;
    right: 6px;

    .items {
      position: relative;
      color: white;
      right: 26px;
      top: -2px;
    }
  }


  .description_containter {
    width: 20%;
    background-color: #8142308c;
    color: white;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    left: 41px;
  }

  .zoom_btn_container {
    top: 104px;
    left: 130px;
    position: relative;
    width: 20%;
  }

  .description_variant_container {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
}

.controls_wrapper {
  margin-top: -8rem;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  flex-direction: column;
  .cart{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    right: 62px;
    position: relative;

  }
}

.name {
  // font-size: 2.4rem;
  margin: 10px 0px;
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
}

.amount {
  margin: 20px 0px;
  font-size: 23px;
  font-weight: 300;
}




.description,
.color {
  margin-bottom: 1rem;
}

.price_wrapper {
  margin-bottom: 2rem;
}

.current_price {
  font-size: 1.8rem;
  font-weight: bold;
}

.crossed_price {
  text-decoration: line-through;
  margin-left: 1rem;
  color: #999;
}

.variants_container{
  margin-bottom: 2rem;
}

.sizes_container {
  margin-bottom: 2rem;
  position:relative;
  right: 4px;
}

.number_of_colors,
.pick_size {
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.variants_wrapper,
.sizes_wrapper {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.color_image_container {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.cyan_button,
.bronze_button,
.black_button {
  font-size: 18px;
  // padding: 20px;
  border: none;
  color: #f3ecec;
  cursor: pointer;
  text-align: auto;
  position: relative;
  // right: 10px;
  right: 90px;
  border: 1px solid black
}

// .cyan_button {
//   // background-color: #12b4c0;
// }

.bronze_button {
  background-color: #de7a09;
}

.black_button {
  background-color: #0a090b;
}

.color_thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.size {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  border-radius: .3125rem;
  width: auto;
  min-width: 4.125rem;
  height: 3.125rem;
  border: 2px solid #9e9e9e;

}

.fill {
  background-color: #000;
  color: #fff;
}

.no_stock {
  opacity: 0.5;
  text-decoration: line-through;
  cursor: not-allowed;
}

.button {
  width: 17%;
  padding: 1rem;
  background-color: #000;
  color: #fff;
  border: none;
  font-size: 14px;
  cursor: pointer;
  margin-top: 1rem;
  border-radius: 30px;
  height: 56px;
  /* Ensure button is not hidden */
}

.tags_wrapper {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.tag,
.tag_alt {
  padding: 0.2rem 0.4rem;
  border-radius: 0.375rem;
  font-size: 1rem;
  font-weight: bold;
}

.tag {
  background-color: #eee;
}

.tag_alt {
  background-color: #000;
  color: #fff;
}