@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

body {
    padding-top: 25px;
    /* padding-bottom: 60px; */
}

header {
    background-color: #333;
    color: white;
    padding: 4px 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.nav-container {
    display: flex;
    justify-content: center;
}

.menu-bar {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    gap: 20px; /* Added spacing between items */
    justify-content: center;
}

.menu-bar li {
    margin: 0 15px;
}

.menu-bar li a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    display: flex;
    align-items: center;
}

.menu-bar li a:hover,
.menu-bar li a.active {
    text-decoration: underline;
}

.fas.fa-shopping-cart {
    margin-right: 5px;
}

main {
    padding: 20px;
    margin-top: 60px; /* Adjust based on header height */
}

.hero {
    background-size: cover;
    color: white;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 60px;
}

.hero h1 {
    font-size: 3rem;
    margin: 0;
    /* font-weight: 400; */
    color: #ca773b; 
    font-weight: 400;
}

.tile-grid {
    display:flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}
.custom-tile-grid {
    display:flex;
    justify-content: center;
    gap: 91px;
    margin-top: 20px;
}

.tile {
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
}

.tile:hover {
    transform: translateY(-5px);
}

.collection-card,
.product-card {
    width: 100%;
    margin: 20px 0;
}
.custom-collection-card,
.product-card {
    width: 30%;
    margin: 20px 0;
}

.collection-image,
.product-image{
    width: 100%;
    height: auto;
    object-fit: cover;
    max-width: 100%;
}
.new-arrival-image {
    width: 100%;
    height: 332px;
    object-fit: cover;
    max-width: 100%;
}

.collection-content,
.product-content,
.new-arrival-content {
    padding: 20px;
    text-align: center;
    flex-grow: 1;
}

.view-collection-button,
.view-product-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.view-collection-button:hover,
.view-product-button:hover {
    background-color: #0056b3;
}

.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.product-box {
    border: 1px solid #ccc;
    padding: 15px;
    text-align: center;
}

.product-box h2 {
    margin: 0 0 10px;
}

.product-box p {
    margin: 0;
}

.product-detail {
    border: 1px solid #ccc;
    padding: 15px;
    text-align: left;
}

.cart-content {
    border: 1px solid #ccc;
    padding: 15px;
}

.cart-box {
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 10px;
}

.authentication-msg{
     display: flex;
     margin-top: 20px;
     text-align: center
}

.login-link {
    /* display: block; */
    margin-top:0px;
    text-align: center;
    color: #007bff;
}

.login-link:hover {
    text-decoration: underline;
}

.footer {
    background-color: #282c34;
    color: white;
    text-align: center;
    padding: 10px 0;
    /* position: fixed; */
    /* bottom: 0; */
    width: 100%;
}
.footer-container {
    display: flex;
    justify-content: space-between;
  }
  
  .column {
    flex: 1;
    text-align: center;
  }

.social-icons i {
    margin: 0 10px;
    font-size: 24px;
    cursor: pointer;
}

.social-icons i:hover {
    color: #61dafb;
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    min-height: 449px;
}

.login-toggle {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.login-toggle button {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.login-toggle button.active {
    background-color: #007bff;
    color: white;
}

.login-form, .signup-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.login-form input, .signup-form input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.login-form button, .signup-form button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.admin-container {
    padding: 20px;
}

.product-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.product-form input,
.product-form button {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

.product-item {
    border: 1px solid #ccc;
    padding: 15px;
    text-align: center;
    border-radius: 10px;
}

.product-item .product-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-width: 100%;
}

.product-item h3 {
    margin-top: 10px;
    margin-bottom: 5px;
}

.product-item p {
    margin: 5px 0;
}

.product-item button {
    padding: 5px 10px;
    margin: 5px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.product-item button:hover {
    background-color: #0056b3;
}

.product-detail-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.product-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product-detail-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.product-detail-info {
    text-align: left;
}

.add-to-cart-button {
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.add-to-cart-button:hover {
    background-color: #555;
}

.cart-container {
    padding: 20px;
    min-height: 449px;
}

.cart-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cart-item {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.cart-summary {
    margin-top: 20px;
    text-align: right;
}

.checkout-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.checkout-button:hover {
    background-color: #0056b3;
}

.demo-message {
    margin-top: 20px;
    color: red;
    font-weight: bold;
}


.success-message {
    background-color: #dff0d8;
    border: 1px solid #d6e9c6;
    color: #3c763d;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 4px;
}

.admin-order-history-container {
    padding: 20px;
    margin-top: 60px;
  
}

.order-history-container{
    min-height: 467px;
}

.order-list {
    list-style-type: none;
    padding: 0;
}

.order-item {
    border: 1px solid #ccc;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 5px;
}

.back-to-admin {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
}

.back-to-admin:hover {
    background-color: #0056b3;
}

.password {
    display: flex;
    justify-content: space-between;
}
.auth-btn {
display: flex;
justify-content: space-around;
text-align: center;
margin: 5px
}